<template>
  <div class="d-flex flex-column flex-grow-1">
    <b-button-group size="sm" class="mb-3">
      <b-button
        v-for="region in regions"
        :disabled="isBusy"
        :key="region"
        :id="region"
        @click="onRegionFilterClick"
        :variant="activeButton === region ? 'primary' : ''"
        class="mr-1"
        style="width: 33%"
      >
        {{ region }}
      </b-button>
    </b-button-group>
    <FilterCustom
      v-if="!isHistoryPage"
      class="mt-4"
      :disabled="isBusy"
      @kuerzelLabelSelected="setKuerzelFilter"
      :options="kuerzelFilterOptions"
      :value="kuerzelFilterValues"
      placeholder="Reisekürzel"
      trackingName="kuerzelId"
      labelName="kuerzelLabel"
    />
    <b-button-group size="sm" class="mt-4 mb-3">
      <b-button
        v-for="deutscherAbflughafen in deutscheAbflughaefen"
        :key="deutscherAbflughafen"
        :id="deutscherAbflughafen"
        :disabled="isBusy"
        @click="onDeutscherAbflughafenFilterClick"
        :variant="
          deutscheAbflughaefenSelected.includes(deutscherAbflughafen)
            ? 'primary'
            : deutscheAbflughaefenSelected.length === 0 && deutscherAbflughafen === 'Alle'
            ? 'primary'
            : ''
        "
        class="mr-1"
        style="width: 33%"
      >
        {{ deutscherAbflughafen }}
      </b-button>
    </b-button-group>
    <b-button-group size="sm" class="mt-4 mb-3">
      <b-button
        v-for="prioritaet in prioritaeten"
        :key="prioritaet.value"
        :disabled="isBusy"
        :id="prioritaet.name"
        @click="onPrioritaetFilterClick"
        :variant="
          prioritaetenSelected.includes(prioritaet.value)
            ? 'primary'
            : prioritaetenSelected.length === 0 && prioritaet.name === 'Alle'
            ? 'primary'
            : ''
        "
        class="mr-1"
        style="width: 33%"
      >
        {{ prioritaet.name }}
      </b-button>
    </b-button-group>
    <b-button-group size="sm" class="mt-4 mb-3">
      <b-button
        v-for="regionsmanager in regionsmanagers"
        :key="regionsmanager"
        :id="regionsmanager"
        :disabled="isBusy"
        @click="onRegionsmanagerFilterClick"
        :variant="
          regionsmanagersSelected.includes(regionsmanager)
            ? 'primary'
            : regionsmanagersSelected.length === 0 && regionsmanager === 'Alle'
            ? 'primary'
            : ''
        "
        class="mr-1"
        style="width: 33%"
      >
        {{ regionsmanager }}
      </b-button>
    </b-button-group>
    <b-form-checkbox
      v-model="nurAkzeptiertCheckbox"
      :disabled="isBusy"
      class="mt-4"
      @change="onChangeAkzeptiertCheckbox"
      switch
    >
      Nur akzeptiert
    </b-form-checkbox>
    <div class="w-100 mt-8 flex-grow-1 flex-column">
      <b-button block variant="danger" @click="resetAllFilters" :disabled="!isAnyFilterApplied">
        Alle Filter zurücksetzen
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  SET_REISEN_FILTER,
  SET_DEUTSCHER_ABFLUGHAFEN_FILTER,
  SET_PRIORITAET_FILTER,
  RESET_PRIORITAET_FILTER,
  SET_REGION_FILTER,
  RESET_REGION_FILTER,
  SET_REGIONSMANAGER_FILTER,
  RESET_REGIONSMANAGER_FILTER,
  SET_NUR_AKZEPTIERT_FILTER,
  RESET_DEUTSCHER_ABFLUGHAFEN_FILTER,
} from '@/core/flugverfuegbarkeit/stores/kpi-fvc.module.js';
import FilterCustom from '@/components/flugverfuegbarkeit/filter-custom.vue';
import regionsmanagerConfig from '@/core/flugverfuegbarkeit/regionsmanager-reisekuerzel.json';
import { mapState, mapGetters } from 'vuex';
import { RESET_ALL_FILTERS } from '../../core/flugverfuegbarkeit/stores/kpi-fvc.module';

export default {
  name: 'KPIFilter',
  components: { FilterCustom },
  props: { isHistoryPage: { type: Boolean } },
  data() {
    return {
      deutscherAbflughafenOptions: [
        { deutscherAbflughafen: 'FRA', id: 1 },
        { deutscherAbflughafen: 'HAM', id: 2 },
        { deutscherAbflughafen: 'MUC', id: 3 },
        { deutscherAbflughafen: 'BER', id: 4 },
        { deutscherAbflughafen: 'DUS', id: 5 },
        { deutscherAbflughafen: 'VIE', id: 6 },
        { deutscherAbflughafen: 'ZRH', id: 7 },
      ],
      regionsmanagerOptions: [
        { regionsmanager: 'NIGO', id: 2 },
        { regionsmanager: 'LEBE', id: 3 },
        { regionsmanager: 'STGE', id: 4 },
        { regionsmanager: 'SYKE', id: 5 },
        { regionsmanager: 'NIEX', id: 6 },
        { regionsmanager: 'SAKI', id: 7 },
      ],
      regions: ['Gesamt', 'Fern', 'EU'],
      deutscheAbflughaefen: ['Alle', 'HAM', 'FRA', 'MUC', 'DUS', 'BER', 'VIE', 'ZRH'],
      prioritaeten: [
        { name: 'Alle', value: 4 },
        { name: 'A', value: 0 },
        { name: 'B', value: 1 },
        { name: 'C', value: 2 },
        { name: 'D', value: 3 },
      ],
      regionsmanagers: ['Alle', 'STGE', 'SYKE'],
      activeButton: 'Gesamt',
      fernRegions: ['Asien', 'Amerika', 'Ozeanien', 'Afrika', 'Orient'],
      euRegions: ['Südeuropa', 'Nordeuropa', 'Dach'],
      REGION_MANAGERS: regionsmanagerConfig,
    };
  },
  computed: {
    ...mapGetters(['getAllGroupedData', 'getAllHistoricData']),
    ...mapState({
      isBusy: state => state.kpiFvc.isBusy,
      regionsmanagersSelected: state => state.kpiFvc.filters.regionsmanager,
      deutscheAbflughaefenSelected: state => state.kpiFvc.filters.deutscherAbflughafen,
      prioritaetenSelected: state => state.kpiFvc.filters.prioritaet,
    }),
    nurAkzeptiertCheckbox: {
      get() {
        return this.$store.state.kpiFvc.filters.nurAkzeptiert;
      },
      set(value) {
        this.$store.commit(SET_NUR_AKZEPTIERT_FILTER, value);
      },
    },
    isAnyFilterApplied() {
      return !(
        this.activeButton === 'Gesamt' &&
        this.kuerzelFilterValues.length === 0 &&
        this.deutscheAbflughaefenSelected.length === 0 &&
        this.prioritaetenSelected.length === 0 &&
        this.regionsmanagersSelected.length === 0 &&
        this.nurAkzeptiertCheckbox === false
      );
    },
    isValueSet() {
      if (this?.$attrs?.value && Array.isArray(this.$attrs.value)) {
        return this.$attrs.value.length > 0;
      }
      return this.$attrs.value;
    },
    kuerzelFilterValues() {
      return this.$store.state.kpiFvc.filters.reisen?.map(filter => ({
        kuerzelId: filter.kuerzelId,
        kuerzelLabel: filter.kuerzelLabel,
      }));
    },

    kuerzelFilterOptions() {
      let kuerzelOptions;
      if (this.activeButton === 'Fern') {
        kuerzelOptions = this.getAllGroupedData
          .filter(item => this.fernRegions.includes(item.region))
          .map((item, id) => {
            return {
              region: item.region,
              kuerzelId: id,
              kuerzelLabel: item.reisekuerzel,
            };
          })
          .sort((a, b) => {
            const reisekuerzelA = a.kuerzelLabel.toLowerCase(),
              reisekuerzelB = b.kuerzelLabel.toLowerCase();
            if (reisekuerzelA < reisekuerzelB)
              //sort string ascending
              return -1;
            if (reisekuerzelA > reisekuerzelB) return 1;
            return 0; //default return value (no sorting)
          })
          .filter(
            (value, index, self) => index === self.findIndex(t => t.kuerzelLabel === value.kuerzelLabel)
          );
        return kuerzelOptions;
      } else if (this.activeButton === 'EU') {
        kuerzelOptions = this.getAllGroupedData
          .filter(item => this.euRegions.includes(item.region))
          .map((item, id) => {
            return {
              region: item.region,
              kuerzelId: id,
              kuerzelLabel: item.reisekuerzel,
            };
          })
          .sort((a, b) => {
            const reisekuerzelA = a.kuerzelLabel.toLowerCase(),
              reisekuerzelB = b.kuerzelLabel.toLowerCase();
            if (reisekuerzelA < reisekuerzelB)
              //sort string ascending
              return -1;
            if (reisekuerzelA > reisekuerzelB) return 1;
            return 0; //default return value (no sorting)
          })
          .filter(
            (value, index, self) => index === self.findIndex(t => t.kuerzelLabel === value.kuerzelLabel)
          );
        return kuerzelOptions;
      }
      return this.$store.state.kpiFvc.reiseFilterOptions?.map(filter => ({
        kuerzelId: filter.id,
        kuerzelLabel: filter.reisekuerzel,
      }));
    },
  },
  destroyed() {
    this.resetAllFilters();
  },
  methods: {
    resetAllFilters() {
      this.activeButton = 'Gesamt';
      this.$store.commit(RESET_ALL_FILTERS);
    },
    onRegionFilterClick($event) {
      const { id } = $event.target;
      if (this.activeButton !== id) {
        this.activeButton = id;
        if (this.activeButton === 'Fern') {
          this.$store.commit(SET_REGION_FILTER, this.fernRegions);
        } else if (this.activeButton === 'EU') {
          this.$store.commit(SET_REGION_FILTER, this.euRegions);
        } else if (this.activeButton === 'Gesamt') {
          this.$store.commit(RESET_REGION_FILTER);
        }
      }
    },
    onDeutscherAbflughafenFilterClick($event) {
      const { id: abflughafen } = $event.target;
      if (abflughafen === 'Alle') {
        this.$store.commit(RESET_DEUTSCHER_ABFLUGHAFEN_FILTER);
      } else if (!this.deutscheAbflughaefenSelected.includes(abflughafen)) {
        this.$store.commit(SET_DEUTSCHER_ABFLUGHAFEN_FILTER, [
          ...this.deutscheAbflughaefenSelected,
          abflughafen,
        ]);
      } else {
        this.$store.commit(
          SET_DEUTSCHER_ABFLUGHAFEN_FILTER,
          this.deutscheAbflughaefenSelected.filter(
            abflughaefenSelected => abflughaefenSelected !== abflughafen
          )
        );
      }

      // Wenn alle angeklickt sind, resette
      if (this.deutscheAbflughaefenSelected.length === this.deutscheAbflughaefen.length - 1) {
        this.$store.commit(RESET_DEUTSCHER_ABFLUGHAFEN_FILTER);
      }
    },
    onPrioritaetFilterClick($event) {
      const { id } = $event.target;
      const prioritaet = this.prioritaeten.find(item => item.name === id).value;
      if (id === 'Alle') {
        this.$store.commit(RESET_PRIORITAET_FILTER);
      } else if (!this.prioritaetenSelected.includes(prioritaet)) {
        this.$store.commit(SET_PRIORITAET_FILTER, [...this.prioritaetenSelected, prioritaet]);
      } else {
        this.$store.commit(
          SET_PRIORITAET_FILTER,
          this.prioritaetenSelected.filter(prioritaetSelected => prioritaetSelected !== prioritaet)
        );
      }

      // Wenn alle angeklickt sind, resette
      if (this.prioritaetenSelected.length === this.prioritaeten.length - 1) {
        this.$store.commit(RESET_PRIORITAET_FILTER);
      }
    },
    onRegionsmanagerFilterClick($event) {
      const { id: regionsmanager } = $event.target;
      if (regionsmanager === 'Alle') {
        this.$store.commit(RESET_REGIONSMANAGER_FILTER);
      } else if (!this.regionsmanagersSelected.includes(regionsmanager)) {
        this.$store.commit(SET_REGIONSMANAGER_FILTER, [...this.regionsmanagersSelected, regionsmanager]);
      } else {
        this.$store.commit(
          SET_REGIONSMANAGER_FILTER,
          this.regionsmanagersSelected.filter(
            regionsmanagerSelected => regionsmanagerSelected !== regionsmanager
          )
        );
      }

      // Wenn alle angeklickt sind, resette
      if (this.regionsmanagersSelected.length === this.regionsmanagers.length - 1) {
        this.$store.commit(RESET_REGIONSMANAGER_FILTER);
      }
    },
    setKuerzelFilter(kuerzelFilterArray) {
      this.$store.commit(SET_REISEN_FILTER, kuerzelFilterArray);
    },

    onChangeAkzeptiertCheckbox() {
      this.$store.commit(SET_NUR_AKZEPTIERT_FILTER, this.nurAkzeptiertCheckbox);
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
